import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay';

import Icons from 'ms-components/icons';
import { CustomMilo } from 'ms-experiments/gamification/CustomMilo';
import type { CustomMiloMonthlyExpeditionItemsState } from 'ms-experiments/gamification/MonthlyExpedition/util';
import type { CustomMilo_student$key } from 'ms-experiments/gamification/__generated__/CustomMilo_student.graphql';
import { PageTimeRecorder } from 'ms-helpers/PageTimeTracker';
import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import StartCheckInButton from 'ms-pages/Lantern/components/StartCheckInButton';
import {
  BodyM,
  Bold,
  HeadingM,
  Underlined,
} from 'ms-pages/Lantern/primitives/Typography';
import {
  getStudentSkillsUrl,
  getStudentSkillsSubstrandUrl,
  getCheckInStrandUrl,
  getCheckInSubstrandGrowthUrl,
} from 'ms-pages/Lantern/utils/urls';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import { StudentSkillsMapSubstrandProficiencyIndicator } from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapSubstrand/StudentSkillsMapSubstrandProficiencyIndicator';
import learningFocusBadge from 'ms-pages/Lantern/views/Student/StudentSkillsMap/assets/learningFocusBadge.svg';
import type { StudentDashboardTheme } from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/urls';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { HSpacer, HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import Tooltip from 'ms-ui-primitives/TooltipNew/TooltipNew';
import { InvariantViolation } from 'ms-utils/app-logging';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import useIsExclusivelyTouchScreen from 'ms-utils/hooks/useIsExclusivelyTouchScreen';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import type { FixGeneratedKey } from 'ms-utils/relay/relay-compiler-language-typescript-fixes';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { SunflowerStudentDashboardHeaderWithLearningFocus_lantern$key } from './__generated__/SunflowerStudentDashboardHeaderWithLearningFocus_lantern.graphql';
import Milo from '../Milo/Milo';
import { SuggestedLearningFocusNotification } from '../SuggestedLearningFocusNotification/SuggestedLearningFocusNotification';
import type {
  Background,
  HolidayBackgroundPreferenceType,
} from '../SunflowerStudentDashboard';

const LearningFocusBadgeImg = styled.img<{}>({
  height: 20,
});
const TooltipContent = styled.div<{}>({
  width: 324,
});
type Props = {
  studentKey: CustomMilo_student$key;
  lanternKey: FixGeneratedKey<SunflowerStudentDashboardHeaderWithLearningFocus_lantern$key>;
  background?: Background | null | undefined;
  retry: () => void;
  holidayBackgroundPreference: HolidayBackgroundPreferenceType;
  setHolidayBackgroundPreference: (
    preference: HolidayBackgroundPreferenceType,
  ) => void;
  theme: StudentDashboardTheme;
  customMiloMonthlyExpeditionItemsState: CustomMiloMonthlyExpeditionItemsState;
};
const PROFICIENCY_INDICATOR_SIZE = 112;
const PROFICIENCY_INDICATOR_SIZE_MOBILE = 80;
export function SunflowerStudentDashboardHeaderWithLearningFocus(props: Props) {
  const {
    featureFlagsV2: {
      gamificationEnableCustomisation,
      gamificationEnableStudentDashboardV2,
    },
  } = useViewer();
  const isDarkTheme = props.theme === 'dark';
  const { trackStructEvent } = useSnowplow();
  const isTouchScreen = useIsExclusivelyTouchScreen();
  const lantern = useFragment(
    graphql`
      fragment SunflowerStudentDashboardHeaderWithLearningFocus_lantern on Lantern {
        substrand(id: $substrandId) {
          id
          ...StudentSkillsMapSubstrandProficiencyIndicator_substrand
          title
          canStartCheckIn
          strand {
            id
          }
        }
        viewer {
          __typename
          ... on LanternStudent {
            learningFocus {
              id
              ...SuggestedLearningFocusNotification_learningFocus
            }
            suggestedLearningFocus {
              id
              ...SuggestedLearningFocusNotification_suggestedLearningFocus
            }
            ...StudentSkillsMapSubstrandProficiencyIndicator_student
            strandStatus(strandId: $strandId) {
              ...StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus
              status
              lastWorkedOnDiagnosticCheckIn {
                id
              }
            }
          }
        }
      }
    `,
    props.lanternKey,
  );
  const suggestedLearningFocusNotification = useBoolean(true);
  const { viewer, substrand } = lantern;
  if (viewer == null || viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('User is not a student');
  }
  const { strandStatus, learningFocus, suggestedLearningFocus } = viewer;
  if (substrand == null) {
    throw new InvariantViolation(
      'Substrand corresponding to the learning focus of the student must exist',
    );
  }
  const { strand } = substrand;
  if (strandStatus == null) {
    throw new InvariantViolation(
      'Strand corresponding to the learning focus of the student must exist',
    );
  }
  const canStartCheckIn = substrand.canStartCheckIn;
  const checkInButtonConfig = makeCheckInButtonConfig({
    studentStrandStatus: strandStatus,
    strandId: strand.id,
    substrandId: substrand.id,
  });
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth != null && windowWidth < breakPoints.small;
  const studentSkillsMapSubstrandProficiencyIndicator = (
    <StudentSkillsMapSubstrandProficiencyIndicator
      studentKey={viewer}
      studentStrandStatusKey={strandStatus}
      substrandKey={substrand}
      size={
        isMobileView
          ? PROFICIENCY_INDICATOR_SIZE_MOBILE
          : PROFICIENCY_INDICATOR_SIZE
      }
    />
  );
  const startCheckInButton = (
    <StartCheckInButton
      size="lanternSmall"
      isDisabled={!canStartCheckIn}
      checkInUrl={checkInButtonConfig.url}
      label={checkInButtonConfig.label}
      onClick={() => {
        trackStructEvent(checkInButtonConfig.event);
      }}
      tooltipContent={
        !canStartCheckIn ? START_CHECK_IN_DISABLED_TOOLTIP_CONTENT : undefined
      }
    />
  );
  return (
    <PageTimeRecorder
      pageName="SunflowerDashboard"
      componentName="SunflowerDashboardHeaderWithLearningFocus"
    >
      {suggestedLearningFocusNotification.value &&
        learningFocus != null &&
        suggestedLearningFocus != null &&
        learningFocus.id !== suggestedLearningFocus.id &&
        !gamificationEnableStudentDashboardV2 && (
          <>
            <SuggestedLearningFocusNotification
              learningFocus={learningFocus}
              suggestedLearningFocus={suggestedLearningFocus}
              onDismiss={suggestedLearningFocusNotification.setFalse}
            />
            <VSpacer height={16} />
          </>
        )}
      <HStack center={!isMobileView}>
        {!isMobileView && (
          <>
            {studentSkillsMapSubstrandProficiencyIndicator}
            <HSpacer width={24} />
          </>
        )}
        <VStack>
          <HStack center>
            <Tooltip
              content={
                <TooltipContent>
                  Your skills focus drives the recommendations on your
                  dashboard.
                  <br />
                  <br />
                  When you start a new task we will suggest the relevant skills
                  focus, or you can change it yourself.{' '}
                  <AnchorButton
                    isInline
                    color="white"
                    href={getStudentSkillsUrl({})}
                    size="lanternSmall"
                    label="Change skills focus"
                  >
                    <Underlined>Change skills focus</Underlined>
                  </AnchorButton>
                </TooltipContent>
              }
            >
              <HStack center>
                <LearningFocusBadgeImg
                  src={learningFocusBadge}
                  alt="skills focus"
                />
                <HSpacer width={8} />

                <BodyM color={isDarkTheme ? 'white' : 'neutralGray'}>
                  <Bold>Your skills focus</Bold>
                </BodyM>
              </HStack>
            </Tooltip>

            <HSpacer width={4} />

            <Tooltip
              content={
                isTouchScreen ? (
                  <AnchorButton
                    isInline
                    color="white"
                    href={getStudentSkillsUrl({})}
                  >
                    Change skills focus
                  </AnchorButton>
                ) : (
                  'Change skills focus'
                )
              }
            >
              {isTouchScreen ? (
                <Icons.Pencil size={24} color={colors.eggplant} />
              ) : (
                <AnchorButton
                  href={getStudentSkillsUrl({})}
                  label="Change skills focus"
                  isInline
                  color="eggplant"
                >
                  <Icons.Pencil size={24} />
                </AnchorButton>
              )}
            </Tooltip>
          </HStack>

          <VSpacer height={8} />

          <HStack center>
            <AnchorButton
              href={getStudentSkillsSubstrandUrl({
                strandId: strand.id,
                substrandId: substrand.id,
              })}
              label={substrand.title}
              isInline
            >
              <HeadingM color={isDarkTheme ? 'white' : 'neutralGray'}>
                {substrand.title}
              </HeadingM>
            </AnchorButton>
          </HStack>
          <VSpacer height={16} />
          <HStack center wrap>
            {startCheckInButton}
          </HStack>
        </VStack>
        {isMobileView && (
          <>
            <HSpacer width={24} />
            {studentSkillsMapSubstrandProficiencyIndicator}
          </>
        )}
        {/* We won't be showing Milo for mobile devices with screen sizes smaller than 480px */}
        {!isMobileView && (
          <>
            {/* Have to keep the spacer small so that all fits on 768px wide screen */}
            <HSpacer width={8} grow />

            {gamificationEnableCustomisation ? (
              <CustomMilo
                studentKey={props.studentKey}
                customMiloMonthlyExpeditionItemsState={
                  props.customMiloMonthlyExpeditionItemsState
                }
              />
            ) : (
              <Milo
                variant={
                  !checkInButtonConfig.secondary ? 'withFlag' : 'regular'
                }
                showHolidayMiloOnHoliday
              />
            )}
          </>
        )}
      </HStack>
    </PageTimeRecorder>
  );
}
function isCheckInToBeResumed(studentStrandStatus: {
  readonly status: 'DIAGNOSTIC' | 'GROWTH';
  readonly lastWorkedOnDiagnosticCheckIn?: {
    readonly id: string;
  } | null;
}) {
  return studentStrandStatus.lastWorkedOnDiagnosticCheckIn != null;
}
export function makeCheckInButtonConfig({
  studentStrandStatus,
  strandId,
  substrandId,
}: {
  studentStrandStatus: {
    readonly status: 'DIAGNOSTIC' | 'GROWTH';
    readonly lastWorkedOnDiagnosticCheckIn?: {
      readonly id: string;
    } | null;
  };
  strandId: string;
  substrandId: string;
}) {
  switch (studentStrandStatus.status) {
    case 'DIAGNOSTIC': {
      const isResumeButton = isCheckInToBeResumed(studentStrandStatus);
      return {
        label: isResumeButton ? 'Resume check-in' : 'Start check-in',
        url: getCheckInStrandUrl({ strandId }),
        event: isResumeButton
          ? ({
              category: 'student_dashboard',
              action: 'clicked_resume_check_in',
              label: 'strand',
            } as const)
          : ({
              category: 'student_dashboard',
              action: 'clicked_start_check_in',
              label: 'strand',
            } as const),
        secondary: false,
      };
    }
    case 'GROWTH': {
      return {
        label: 'Start check-in',
        url: getCheckInSubstrandGrowthUrl({ substrandId }),
        event: {
          category: 'student_skills_map_substrand',
          action: 'clicked_start_check_in',
          label: 'substrand',
        },
        secondary: true,
      } as const;
    }
    default: {
      assertUnreachable(
        studentStrandStatus.status,
        `Unknown status: ${JSON.stringify(studentStrandStatus.status)}`,
      );
    }
  }
}
